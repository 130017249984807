import { UserPermissions } from "../../dtos/enums/userPermissions";
import { UserFilePermissions } from "../../dtos/enums/userFilePermissions";

export enum LoginStatus {
  Unknown = "A",
  LoggingIn = "B",
  LoggingOut = "C",
  LoggedIn = "D",
  LoggedOut = "E",
}

export interface IUserIdentity {
  email: string;
  fullName: string;
  userPermissions: UserPermissions;
  filePermissions: UserFilePermissions;
}

export interface ISessionInfo {
  sessionId: string;
  sharedKey: Uint8Array;
  lastChecked: Date;
}

export interface IInfoLabelSettings {
  x: number;
  y: number;
  color: string;
  fontSize: number;
  visible: boolean;
}

export interface IImageInfoDisplaySettings {
  patientIdLabel: IInfoLabelSettings;
  sliceLabel: IInfoLabelSettings;
  laterality: IInfoLabelSettings;
  sliceIndicator: IInfoLabelSettings;
  syncWindow: boolean;
}

export const defaultImageInfoDisplaySettings: IImageInfoDisplaySettings = {
  patientIdLabel: { x: 16, y: 16, fontSize: 20, color: "variable1", visible: true },
  sliceLabel: { x: 256, y: 16, fontSize: 20, color: "rgba(6,147,227,0.9)", visible: true },
  laterality: { x: 256, y: 16, fontSize: 20, color: "rgba(252,185,0,0.9)", visible: false },

  sliceIndicator: { x: 0, y: 0, fontSize: 0, color: "rgba(255,255,163,0.8)", visible: true },
  syncWindow: true,
};

export interface IReviewFilterSettings {
  enabled: boolean;
  minLabel: number;
  note: boolean;
}

export const defaultReviewFilterSettings: IReviewFilterSettings = {
  enabled: false,
  minLabel: 4,
  note: true,
};

export type ILoginState =
  | {
      loginStatus: LoginStatus.LoggedIn | LoginStatus.LoggingOut;
      sessionInfo: ISessionInfo;
      userIdentity: IUserIdentity;
      imageInfoDisplaySettings: IImageInfoDisplaySettings;
      reviewFilterSettings: IReviewFilterSettings;
    }
  | {
      loginStatus: LoginStatus.Unknown | LoginStatus.LoggingIn | LoginStatus.LoggedOut;
      sessionInfo: undefined;
      userIdentity: undefined;
      imageInfoDisplaySettings: IImageInfoDisplaySettings;
      reviewFilterSettings: IReviewFilterSettings;
    };
