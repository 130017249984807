import React from "react";

import logo from "./mta-ttk-akk-logo-en-retina-w.png";

export const BigLogoComponent: React.SFC = () => {
  return (
    <div>
      <img src={logo} alt="" />
    </div>
  );
};
