import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { LocalDB } from "../modules/localDB/localDB";
import { LocalDBContext } from "../modules/localDb.context";
import { useContext } from "react";
import { IReviewFilterSettings } from "../state/session/session.state";

export interface IStepInfo {
  name: string;
  labelingModes: string[];
  lowerSliceLimit?: number;
  upperSliceLimit?: number;
}

export interface IFileListData {
  me: {
    id: string;
    email: string;

    workpacksForReview: Array<{
      id: string;
      name: string;
      hasBeenCompleted: boolean;
      assignees: {
        email: string;
        fullName: string;
        progressPercent: number;
      }[];
      patients: Array<{
        id: string;
        hasNote: boolean;
        maxLabel: number;
      }>;
    }>;

    currentProgresses: Array<{
      currentPatient: string;
      completedPatientIds: string[];
      patientProgresses: Array<{
        id: string;
        currentStep: number;
        completedSteps: number[];
      }>;
      workpack: {
        id: string;
        name: string;
        sequenceTypes: string[];
        labelingModes: Array<{ name: string; labels: string[]; defaultLabel: number }>;
        workflow: Array<IStepInfo>;
        patients: Array<{
          id: string;
          source: string;
          bodyPart: string;
          sequenceTypes: string[];
          hasNote: boolean;
          maxLabel: number;
          notes: Array<{ email: string; note: string }>;
          files: Array<{
            id: string;
            bodyPart: string;
            startingSlice?: number;
            sliceCenters?: number[];
            seqType: string;
            source: string;
          }>;
        }>;
      };
    }>;
  };
}

export const userFileListQuery = gql`
  query userFileList {
    me {
      id
      email

      workpacksForReview {
        name
        id
        hasBeenCompleted

        assignees {
          email
          fullName
          progressPercent
        }

        patients {
          id
          hasNote
          maxLabel
        }
      }

      currentProgresses {
        currentPatient
        completedPatientIds
        patientProgresses {
          id
          currentStep
          completedSteps
        }
        workpack {
          id
          name
          sequenceTypes
          labelingModes {
            name
            labels
            defaultLabel
          }
          workflow {
            name
            labelingModes
            lowerSliceLimit
            upperSliceLimit
          }
          patients {
            id
            source
            bodyPart
            sequenceTypes
            hasNote
            maxLabel
            notes {
              email
              note
            }
            files {
              id
              bodyPart
              startingSlice
              sliceCenters
              seqType
              source
              images_no
              modality
            }
          }
        }
      }
    }
  }
`;
export function useUserFileList() {
  return useQuery<IFileListData>(userFileListQuery);
}
