import React from "react";
import { Typography, Switch, Paper, FormControlLabel } from "@material-ui/core";
import { IImageInfoDisplaySettings } from "../../state/session/session.state";
import { useStyles } from "./infoLabelSettingsCard.styles";

export const MiscSettingsCard: React.FC<{
  displaySettings: IImageInfoDisplaySettings;
  darkMode: boolean;
  save: (value: IImageInfoDisplaySettings, darkMode: boolean) => void;
}> = ({ displaySettings, darkMode, save }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.settingsPaper}>
      <Typography variant="h4" className={classes.labelCardTitle}>
        <span> Egyeb beallitasok </span>
      </Typography>
      <Typography variant="body1">Megjelenik a vizsgalat, ha: </Typography>

      <form className={classes.settingsForm}>
        <FormControlLabel
          control={<Switch checked={darkMode} onClick={() => save(displaySettings, !darkMode)} />}
          color="primary"
          label="Sotet mod"
        />
        <FormControlLabel
          control={
            <Switch
              checked={displaySettings.syncWindow}
              onClick={() => save({ ...displaySettings, syncWindow: !displaySettings.syncWindow }, darkMode)}
            />
          }
          color="primary"
          label="Csatolt ablakolas"
        />
      </form>
    </Paper>
  );
};
