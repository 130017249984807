import { combineEpics, Epic } from "redux-observable";

import { filter, mergeMap, withLatestFrom } from "rxjs/operators";

import { isActionOf } from "typesafe-actions";

import { WebApi } from "../../modules/api/webApi";
import { WebApiError } from "../../modules/api/WebApiError";
import { OrthopredAction } from "../actions";
import { OrthopredState } from "../reducers";

import {
  listUsers,
  setPermissionForUser,
  setUserFilePermissionForUser,
  setFilePermissionForUser,
  adminListFiles,
  loadSequenceTypes,
  setUserLabelSettings,
  createWorkpack,
  getWorkpacks,
  assignWorkpack,
} from "./admin.actions";
import { LoginStatus } from "../session/session.state";
import { UserPermission, UserPermissions } from "../../dtos/enums/userPermissions";
import { FilePermissions } from "../../dtos/enums/filePermissions";
import { UserFilePermissions } from "../../dtos/enums/userFilePermissions";

const listUsersEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(listUsers.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ListUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const cursor = action.payload.cursor || 0;

        const res = await api.listUsers(sessionId, sharedKey, cursor);

        return listUsers.success({
          users: res.users.map((u: any) => ({
            email: u.email,
            name: u.name,
            userPermissions: UserPermissions.deserialize(u.userPermissions),
            filePermissions: UserFilePermissions.deserialize(u.filePermissions),
          })),
          cursor: res.cursor,
          originalCursor: cursor,
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return listUsers.failure(err);
        }

        throw err;
      }
    })
  );
};

const loadSequenceTypesEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(loadSequenceTypes.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ListUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.getSequenceTypes(sessionId, sharedKey);

        return loadSequenceTypes.success({
          sequenceTypes: res,
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return loadSequenceTypes.failure(err);
        }

        throw err;
      }
    })
  );
};

const listFilesEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(adminListFiles.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const cursor = action.payload.loadMore ? state.admin.fileList[action.payload.sequenceType].cursor || 0 : 0;
        const emails = action.payload.emails || [];

        const res = await api.listFiles(sessionId, sharedKey, cursor, action.payload.sequenceType, emails);
        res.files.forEach((a: any) =>
          Object.keys(a.userPermissions).forEach(
            (k) => (a.userPermissions[k] = FilePermissions.deserialize(a.userPermissions[k]))
          )
        );
        return adminListFiles.success({
          files: res.files,
          cursor: res.cursor,
          originalCursor: cursor,
          sequenceType: action.payload.sequenceType,
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          err.originalPayload = action.payload;
          return adminListFiles.failure(err);
        }

        throw err;
      }
    })
  );
};

const setPermissionForUserEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(setPermissionForUser.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ManageUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.setUserPermission(
          action.payload.email,
          action.payload.action,
          action.payload.permission,
          sessionId,
          sharedKey
        );

        return setPermissionForUser.success({
          email: action.payload.email,
          newPermission: UserPermissions.deserialize(res.newPermissions),
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return setPermissionForUser.failure(err);
        }

        throw err;
      }
    })
  );
};

const setFilePermissionForUserEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(setFilePermissionForUser.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ManageUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.setFilePermission(
          action.payload.email,
          action.payload.fileId,
          action.payload.action,
          action.payload.permission,
          sessionId,
          sharedKey
        );

        return setFilePermissionForUser.success({
          email: action.payload.email,
          fileId: action.payload.fileId,
          newPermission: FilePermissions.deserialize(res.newPermissions),
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return setFilePermissionForUser.failure(err);
        }

        throw err;
      }
    })
  );
};

const setUserFilePermissionForUserEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(setUserFilePermissionForUser.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ManageUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.setUserFilePermission(
          action.payload.email,
          action.payload.action,
          action.payload.permission,
          sessionId,
          sharedKey
        );

        return setUserFilePermissionForUser.success({
          email: action.payload.email,
          newPermission: UserFilePermissions.deserialize(res.newPermissions),
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return setUserFilePermissionForUser.failure(err);
        }

        throw err;
      }
    })
  );
};

const setUserLabelSettingsEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(setUserLabelSettings.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ListUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.setUserLabelSettings(
          action.payload.email,
          action.payload.sequenceTypes,
          action.payload.labels,
          sessionId,
          sharedKey
        );

        return setUserLabelSettings.success(res);
      } catch (err) {
        if (err instanceof WebApiError) {
          return setUserLabelSettings.failure(err);
        }

        throw err;
      }
    })
  );
};

const getWorkpacksEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(getWorkpacks.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ListUsers)) {
          throw new Error("NoPermission");
        }
        const originalCursor = action.payload.isLoadMore ? state.admin.workpackList.cursor : 0;

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.getWorkpacks(originalCursor, sessionId, sharedKey);

        return getWorkpacks.success({
          originalCursor,
          cursor: res.cursor,
          workpacks: res.workpacks,
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return getWorkpacks.failure(err);
        }

        throw err;
      }
    })
  );
};

const createWorkpackEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(createWorkpack.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ListUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.createWorkpack(
          action.payload.name,
          action.payload.files,
          action.payload.sequenceTypes,
          sessionId,
          sharedKey
        );

        return createWorkpack.success({
          ...action.payload,
          id: res.id,
          creator: state.loginState.userIdentity.email,
          created: new Date(res.created),
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return createWorkpack.failure(err);
        }

        throw err;
      }
    })
  );
};

const assignWorkpackEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(assignWorkpack.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        if (!state.loginState.userIdentity.userPermissions.check(UserPermission.ListUsers)) {
          throw new Error("NoPermission");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.assignWorkpack(action.payload.email, action.payload.packId, sessionId, sharedKey);

        return assignWorkpack.success({ files: res.files, id: action.payload.packId, email: action.payload.email });
      } catch (err) {
        if (err instanceof WebApiError) {
          return assignWorkpack.failure(err);
        }

        throw err;
      }
    })
  );
};
export const adminEpics = combineEpics(
  listUsersEpic,
  listFilesEpic,
  setPermissionForUserEpic,
  setFilePermissionForUserEpic,
  setUserFilePermissionForUserEpic,
  loadSequenceTypesEpic,
  setUserLabelSettingsEpic,
  getWorkpacksEpic,
  createWorkpackEpic,
  assignWorkpackEpic
);
