import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { SettingsComponent } from "./settings.component";
import { IImageInfoDisplaySettings, IReviewFilterSettings } from "../../state/session/session.state";
import { saveImageInfoDisplaySettings, saveReviewSettings } from "../../state/session/session.actions";

export const SettingsContainer = connect(
  (state: OrthopredState) => {
    return {
      settings: state.loginState.imageInfoDisplaySettings,
      reviewFilterSettings: state.loginState.reviewFilterSettings,
    };
  },
  (dispatch) => {
    return {
      saveSettings: (settings: IImageInfoDisplaySettings) => {
        dispatch(saveImageInfoDisplaySettings.request(settings));
      },
      saveReviewSettings: (settings: IReviewFilterSettings) => {
        dispatch(saveReviewSettings.request(settings));
      },
    };
  }
)(SettingsComponent);
