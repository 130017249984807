import { combineReducers } from "redux";

import { StateType } from "typesafe-actions";

import { loginState } from "./session/session.reducers";
import { filesState } from "./file/file.reducers";
import { errorReducer } from "./errors/errors.reducer";
import { adminReducer } from "./admin/admin.reducers";
import { taskReducer } from "./task/task.reducer";
import { registrationState } from "./registration/registration.reducers";

export const reducers = combineReducers({
  loginState,
  files: filesState,
  error: errorReducer,
  admin: adminReducer,
  task: taskReducer,
  register: registrationState,
});

export type OrthopredState = StateType<typeof reducers>;
