import React, { useContext, useMemo } from "react";
import { Container } from "@material-ui/core";

import { LoadingView } from "../loadingView.component";
import { useUserFileList } from "../../queries/fileList.query";
import { Redirect } from "react-router-dom";
import { LocalDBContext } from "../../modules/localDb.context";

export const OpenCurrentPatientComponent: React.FC = () => {
  const localDb = useContext(LocalDBContext);
  const { data, called, loading, error } = useUserFileList();

  const currentProgresses = useMemo(() => {
    const filterSettings = data && localDb.getReviewFilterSettings(data.me.email);
    return data?.me.currentProgresses.map((progress) => ({
      ...progress,
      workpack: {
        ...progress.workpack,
        patients: progress.workpack.patients
          .sort((a, b) => a.id.localeCompare(b.id))
          .filter(
            (p) =>
              !filterSettings ||
              !filterSettings.enabled ||
              (filterSettings.note && p.hasNote) ||
              p.maxLabel >= filterSettings.minLabel
          ),
      },
    }));
  }, [localDb, data]);

  if (!called || loading) {
    return <LoadingView />;
  }

  if (!data || !currentProgresses || error) {
    return <main>{JSON.stringify(error)}</main>;
  }

  return (
    <Container component="main" maxWidth="lg">
      {currentProgresses.length > 0 ? (
        <Redirect
          to={`/images/${currentProgresses[0].workpack.id}/${
            currentProgresses[0].currentPatient || currentProgresses[0].workpack.patients[0].id
          }`}
        />
      ) : (
        <Redirect to="/images/" />
      )}
    </Container>
  );
};
