import { combineEpics, Epic } from "redux-observable";

import { filter, mergeMap, withLatestFrom } from "rxjs/operators";

import { isActionOf } from "typesafe-actions";

import { WebApi } from "../../modules/api/webApi";
import { WebApiError } from "../../modules/api/WebApiError";
import { OrthopredAction } from "../actions";
import { OrthopredState } from "../reducers";
import { checkSession, login, logout, saveImageInfoDisplaySettings, saveReviewSettings } from "./session.actions";

import * as jsrp from "jsrp";
import * as base64 from "base64-js";
import scryptAsync from "scrypt-async";
import { LoginStatus } from "./session.state";
import { fromHexString } from "../../modules/util";
import { LocalDB } from "../../modules/localDB/localDB";
import { ValidationService } from "../../modules/validation/validationService";
import { UserPermissions } from "../../dtos/enums/userPermissions";
import { UserFilePermissions } from "../../dtos/enums/userFilePermissions";

const loginEpic: Epic<
  OrthopredAction,
  OrthopredAction,
  OrthopredState,
  { api: WebApi; localDB: LocalDB; validationService: ValidationService }
> = (action$, state$, { api, localDB, validationService }) => {
  return action$.pipe(
    filter(isActionOf(login.request)),
    mergeMap(async (action) => {
      const clientSRP = new jsrp.client();
      await new Promise<void>((res) => clientSRP.init({ username: action.payload.email, password: "" }, res));

      try {
        /**
         * Using a bit of undocumented APIs for both jsrp and scrypt-async
         */
        const A = clientSRP.getPublicKey();
        const challenge = await api.getLoginChallenge(action.payload.email, A);
        const challengeSaltBytes = base64.toByteArray(challenge.salt);
        const stretchedPassword = await new Promise((res) =>
          scryptAsync(
            action.payload.password as any,
            base64.toByteArray(challenge.stretchSalt) as any,
            Object.assign({ encoding: "binary" }, challenge.passwordStretchParams.params),
            res
          )
        );
        (clientSRP as any).PBuf = stretchedPassword;

        clientSRP.setSalt(challengeSaltBytes as any);
        clientSRP.setServerPublicKey(challenge.B);

        const loginRes = await api.authenticate(action.payload.email, A, clientSRP.getProof());
        clientSRP.checkServerProof(loginRes.serverProof);
        localDB.saveSessionInfo(loginRes.sessionId, fromHexString(clientSRP.getSharedKey()));
        const imageInfoDisplaySettings = await localDB.getImageInfoDisplaySettings(action.payload.email);
        const reviewFilterSettings = await localDB.getReviewFilterSettings(action.payload.email);

        return login.success({
          loginStatus: LoginStatus.LoggedIn,
          imageInfoDisplaySettings,
          reviewFilterSettings,
          userIdentity: {
            email: action.payload.email,
            fullName: loginRes.userIdentity.fullName,
            userPermissions: UserPermissions.deserialize(loginRes.userPermissions),
            filePermissions: UserFilePermissions.deserialize(loginRes.filePermissions),
          },
          sessionInfo: {
            sessionId: loginRes.sessionId,
            sharedKey: fromHexString(clientSRP.getSharedKey()),
            lastChecked: new Date(),
          },
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return login.failure(validationService.parseError(err) || err);
        }

        throw err;
      }
    })
  );
};

const checkSessionEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi; localDB: LocalDB }> = (
  action$,
  state$,
  { api, localDB }
) => {
  return action$.pipe(
    filter(isActionOf(checkSession.request)),
    mergeMap(async (action) => {
      const sessionInfo = localDB.getSessionInfo();
      if (!sessionInfo) {
        return checkSession.failure(new WebApiError(403, "NoSessionIdPresent"));
      }
      const { sessionId, sharedKey } = sessionInfo;
      try {
        const resp = await api.checkSession(sessionId, sharedKey);
        const imageInfoDisplaySettings = await localDB.getImageInfoDisplaySettings(resp.email);
        const reviewFilterSettings = await localDB.getReviewFilterSettings(resp.email);
        return checkSession.success({
          loginStatus: LoginStatus.LoggedIn,
          userIdentity: {
            email: resp.email,
            fullName: resp.userIdentity.fullName,
            userPermissions: UserPermissions.deserialize(resp.userPermissions),
            filePermissions: UserFilePermissions.deserialize(resp.filePermissions),
          },
          imageInfoDisplaySettings,
          reviewFilterSettings,
          sessionInfo: {
            sessionId,
            sharedKey,
            lastChecked: new Date(),
          },
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return checkSession.failure(err);
        }

        throw err;
      }
    })
  );
};

const logoutEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi; localDB: LocalDB }> = (
  action$,
  state$,
  { api, localDB }
) => {
  return action$.pipe(
    filter(isActionOf(logout.request)),
    mergeMap(async (action) => {
      const sessionInfo = localDB.getSessionInfo();
      if (!sessionInfo) {
        return logout.success();
      }
      const { sessionId, sharedKey } = sessionInfo;
      try {
        await api.logout(sessionId, sharedKey);
        localDB.clearSessionInfo();
        return logout.success();
      } catch (err) {
        if (err instanceof WebApiError) {
          return logout.failure(err);
        }

        throw err;
      }
    })
  );
};

const saveImageInfoDisplaySettingsEpic: Epic<
  OrthopredAction,
  OrthopredAction,
  OrthopredState,
  { api: WebApi; localDB: LocalDB }
> = (action$, state$, { api, localDB }) => {
  return action$.pipe(
    filter(isActionOf(saveImageInfoDisplaySettings.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        localDB.saveImageInfoDisplaySettings(state.loginState.userIdentity.email, action.payload);
        return saveImageInfoDisplaySettings.success(action.payload);
      } catch (err) {
        return saveImageInfoDisplaySettings.failure(err);
      }
    })
  );
};

const saveReviewFilterSettingsEpic: Epic<
  OrthopredAction,
  OrthopredAction,
  OrthopredState,
  { api: WebApi; localDB: LocalDB }
> = (action$, state$, { api, localDB }) => {
  return action$.pipe(
    filter(isActionOf(saveReviewSettings.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        localDB.saveReviewFilterSettings(state.loginState.userIdentity.email, action.payload);
        return saveReviewSettings.success(action.payload);
      } catch (err) {
        return saveReviewSettings.failure(err);
      }
    })
  );
};

export const sessionEpics = combineEpics(
  loginEpic,
  checkSessionEpic,
  logoutEpic,
  saveImageInfoDisplaySettingsEpic,
  saveReviewFilterSettingsEpic
);
