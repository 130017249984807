import { ApolloProvider } from "@apollo/react-hooks";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ApolloClient from "apollo-boost";
import * as base64 from "base64-js";
import { History } from "history";
import React, { Suspense, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch as RouteSwitch } from "react-router-dom";

import "./App.css";

import { FileListComponent } from "./components/fileList/fileList.component";
import { OpenCurrentPatientComponent } from "./components/fileList/openCurrent.component";
import { SettingsContainer } from "./components/imageInfoDisplayeSettings/settings.container";
import { LabelModeListComponent } from "./components/labelMode/labelModeList.component";
import { LayoutContainer } from "./components/layout/layout.container";
import { LoadingView } from "./components/loadingView.component";
import { LoginContainer } from "./components/login/login.container";
import { NotFoundComponent } from "./components/notFound/notFound.component";
import { RegistrationContainer } from "./components/registration/registration.container";
import { UploadContainer } from "./components/upload/upload.container";
import { UserListComponent } from "./components/userManagement/userList.component";
import { UserManagementViewContainer } from "./components/userManagement/userManagement.container";
import { WorkflowListComponent } from "./components/workflow/workflowList.component";
import { WorkpackEditorContainer } from "./components/workpackEditor/workpackEditor.container";
import WorkpackListComponent from "./components/workpackList/workpackList.component";
import { WorkpackViewContainer } from "./components/workpackView/workpackView.container";
import { WebApi } from "./modules/api/webApi";
import { LocalDB } from "./modules/localDB/localDB";
import { ValidationService } from "./modules/validation/validationService";
import { configureStore } from "./state/store";
import { getTheme } from "./theme";

const FileViewComponent = React.lazy(() =>
  import("./components/fileView/fileView.component").then((m) => ({ default: m.FileViewComponent }))
);

function hexString(buffer: Uint8Array) {
  const byteArray = new Uint8Array(buffer);

  const hexCodes = Array.from(byteArray).map((value) => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, "0");
    return paddedHexCode;
  });

  return hexCodes.join("");
}

async function digestMessage(headerString: string, keyData: Uint8Array, body?: Uint8Array): Promise<Uint8Array> {
  const encoder = new TextEncoder();
  const headers = encoder.encode(headerString);

  let data: Uint8Array;
  if (body) {
    data = new Uint8Array(headers.length + body.length);
    data.set(headers, 0);
    data.set(body, headers.length);
  } else {
    data = headers;
  }

  const key = await window.crypto.subtle.importKey(
    "raw",
    keyData,
    {
      name: "HMAC",
      hash: { name: "SHA-256" },
    },
    false,
    ["sign"]
  );
  return new Uint8Array(await window.crypto.subtle.sign("HMAC", key, data));
}

const webApi = new WebApi(
  process.env.NODE_ENV === "production" ? "" : "http://localhost:3000",
  fetch.bind(window),
  XMLHttpRequest
);
const localDB = new LocalDB(localStorage, sessionStorage, indexedDB);
const validationService = new ValidationService();

const store = configureStore(webApi, localDB, validationService);
const loggedInLayout = (props: { history: History }) => {
  const client = new ApolloClient({
    uri: (process.env.NODE_ENV === "production" ? "" : "http://localhost:3000") + "/api/gql",
    fetch: async (input: RequestInfo, init: RequestInit) => {
      const sessionId = sessionStorage.getItem("sessionId");
      const sharedKey = base64.toByteArray(sessionStorage.getItem("sharedKey")!);

      const signDate = new Date().toISOString();

      const encoder = new TextEncoder();
      const bodyAsBytes = encoder.encode(init.body as string);

      const textToSign = `/api/gql,${signDate},${sessionId}`.toUpperCase();
      const signature = await digestMessage(textToSign, sharedKey, bodyAsBytes);

      const headers = new Headers(init.headers);
      headers.set("Signaturedate", signDate);
      headers.set("SessionId", sessionId!);
      headers.set("Authorization", `Session ${hexString(signature)}`);

      return fetch(input, {
        ...init,
        headers,
      });
    },
  });
  return (
    <ApolloProvider client={client}>
      <LayoutContainer {...props}>
        <Suspense fallback={<LoadingView />}>
          <RouteSwitch>
            <Route path="/images/:packId/:patientId" component={FileViewComponent} />
            <Route path="/images" component={FileListComponent} />
            <Route path="/image" component={OpenCurrentPatientComponent} />
            <Route path="/settings" component={SettingsContainer} />
            <Route path="/upload" component={UploadContainer} />
            <Route path="/users/:email" component={UserManagementViewContainer} />
            <Route path="/users" component={UserListComponent} />
            <Route path="/workpacks/new" component={WorkpackEditorContainer} />
            <Route path="/workpacks/:packId" component={WorkpackViewContainer} />
            <Route path="/workpacks" component={WorkpackListComponent} />
            <Route path="/labelModes" component={LabelModeListComponent} />
            <Route path="/workflows" component={WorkflowListComponent} />
            <Route component={NotFoundComponent} />
          </RouteSwitch>
        </Suspense>
      </LayoutContainer>
    </ApolloProvider>
  );
};
const App: React.FC = () => {
  const [themeType, setThemeType] = useState<"light" | "dark">(
    (localStorage.getItem("preferredTheme") as "light" | "dark") || "light"
  );
  window.addEventListener("storage", () => {
    // When local storage changes, dump the list to
    // the console.
    setThemeType((localStorage.getItem("preferredTheme") as "light" | "dark") || "light");
  });
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={getTheme(themeType)}>
          <CssBaseline />
          <div className="App">
            <RouteSwitch>
              <Route path="/login" component={LoginContainer} />
              <Route path="/signup" component={RegistrationContainer} />
              <Route path="/" exact={true}>
                <Redirect to="/login" />
              </Route>
              <Route component={loggedInLayout} />
            </RouteSwitch>
          </div>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
