import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Container,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Typography,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import OpenIcon from "@material-ui/icons/OpenInBrowser";
import ErrorIcon from "@material-ui/icons/Error";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";

import { History } from "history";

import { useStyles } from "./fileList.style";
import { LoadingView } from "../loadingView.component";
import { LocalDBContext } from "../../modules/localDb.context";
import { useFileDownloader } from "../../modules/file.hooks";
import { userFileListQuery, useUserFileList } from "../../queries/fileList.query";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { FinishPackModalComponent } from "../finishPackModal/finishPackModal.component";
import { Check } from "@material-ui/icons";

export interface IFileListComponentProps {
  history: History;
}

export const FileListComponent: React.FC<IFileListComponentProps> = ({ history }: IFileListComponentProps) => {
  const classes = useStyles();

  const localDb = useContext(LocalDBContext);
  const [downloadedFileIds, setDownloadedFileIds] = useState<string[] | undefined>(undefined);
  const [tasks, downloadFile] = useFileDownloader();

  const [assignWorkpack, { loading: assignLoading }] = useMutation(
    gql`
      mutation assign($email: String!, $packId: String!) {
        assignPack(email: $email, packId: $packId, unassignOthers: true, unassignWorkpacks: true) {
          __typename
          id
          assignees {
            __typename
            email
            fullName
            currentPatient
            progressPercent
            patientProgresses {
              id
              currentStep
              completedSteps
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: userFileListQuery }],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    if (!downloadedFileIds || tasks.successes.some((t) => !downloadedFileIds.includes(t.id))) {
      localDb.getStoredFiles().then(setDownloadedFileIds, (err) => {
        setDownloadedFileIds([]);
        // tslint:disable-next-line:no-console
        console.error(err);
      });
    }
  }, [downloadedFileIds, localDb, tasks]);
  const { data, called, loading, error } = useUserFileList();

  const [finishModalOpen, setFinishModalOpen] = useState<string | undefined>(undefined);

  const currentProgresses = useMemo(() => {
    const filterSettings = data && localDb.getReviewFilterSettings(data.me.email);
    return data?.me.currentProgresses.map((progress) => ({
      ...progress,
      workpack: {
        ...progress.workpack,
        patients: progress.workpack.patients
          .sort((a, b) => a.id.localeCompare(b.id))
          .filter(
            (p) =>
              !filterSettings ||
              !filterSettings.enabled ||
              (filterSettings.note && p.hasNote) ||
              p.maxLabel >= filterSettings.minLabel
          ),
      },
    }));
  }, [localDb, data]);

  if (!called || loading) {
    return <LoadingView />;
  }

  if (!data || !currentProgresses || error) {
    return <main>{JSON.stringify(error)}</main>;
  }

  return (
    <Container component="main" maxWidth="lg">
      {currentProgresses.length === 0 && data.me.workpacksForReview.length === 0 && (
        <section className={classes.listContainer}>
          <Typography variant="h3">Nincs hozzad rendelt csomag</Typography>
        </section>
      )}
      {currentProgresses.map((prog) => {
        const wp = prog.workpack;
        return (
          <section key={wp.id} className={classes.listContainer}>
            <FinishPackModalComponent
              onClose={() => setFinishModalOpen(undefined)}
              pack={wp}
              open={finishModalOpen === wp.id}
            />
            <Typography variant="h3">{wp.name}</Typography>
            <Typography variant="subtitle1">{wp.sequenceTypes.join(" - ")}</Typography>

            <List>
              {wp.patients.map((p) => {
                const pprog = prog.patientProgresses.find((pp) => pp.id === p.id);
                const canOpen = wp.sequenceTypes.every((s) => p.sequenceTypes.includes(s));
                const downloaded =
                  canOpen &&
                  downloadedFileIds &&
                  wp.sequenceTypes.every((s) => downloadedFileIds.includes(p.files.find((f) => f.seqType === s)!.id));
                const downloading =
                  canOpen &&
                  wp.sequenceTypes.some((s) =>
                    tasks.inProgressTasks.find((t) => t.id === p.files.find((f) => f.seqType === s)!.id)
                  );

                return (
                  <ListItem key={p.id} dense={true}>
                    <ListItemIcon>
                      <div className={classes.userProgress}>
                        <CircularProgress variant="determinate" value={100} color={"secondary"} />
                        <CircularProgress
                          value={pprog ? (pprog.completedSteps.length / wp.workflow.length) * 100 : 0}
                          variant="static"
                        />
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={p.id} secondary={`${p.source}${p.bodyPart ? " - " + p.bodyPart : ""}`} />
                    <ListItemSecondaryAction>
                      {downloaded ? (
                        <>
                          <OpenIcon color="primary" onClick={() => history.push(`/images/${wp.id}/${p.id}`)} />
                          <DeleteIcon
                            color="error"
                            onClick={() =>
                              p.files.forEach((f) =>
                                localDb.removeFile(f.id).then(() => setDownloadedFileIds(undefined))
                              )
                            }
                          />
                        </>
                      ) : downloading ? (
                        <CircularProgress color="primary" />
                      ) : canOpen ? (
                        <DownloadIcon
                          color="primary"
                          onClick={() =>
                            wp.sequenceTypes.forEach(async (s) => {
                              const f = p.files.find((cf) => cf.seqType === s);
                              downloadFile(f!.id, p.id, s, f);
                            })
                          }
                        />
                      ) : (
                        <ErrorIcon />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              <ListItem onClick={() => setFinishModalOpen(wp.id)} button={true} color="primary">
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText>Keszre jeloles</ListItemText>
              </ListItem>
            </List>
          </section>
        );
      })}

      {data.me.workpacksForReview.length > 0 && (
        <section className={classes.listContainer}>
          <Typography variant="h3"> Ellenorizendo csomagok </Typography>
          <List>
            {data?.me.workpacksForReview
              .filter((wp) => wp.hasBeenCompleted && wp.assignees.length === 0)
              .map((wp) => (
                <ListItem key={wp.id} dense={true}>
                  <ListItemText
                    primary={wp.name}
                    secondary={`Max: ${Math.max(...wp.patients.map((p) => p.maxLabel))}, megjegyzes ${
                      wp.patients.filter((p) => p.hasNote).length
                    } vizsgalaton`}
                  />
                  <ListItemSecondaryAction
                    onClick={() =>
                      !assignLoading &&
                      assignWorkpack({
                        variables: {
                          packId: wp.id,
                          email: data.me.email,
                        },
                      })
                    }
                  >
                    {assignLoading ? <CircularProgress /> : <AssignmentTurnedIn />}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

            {data.me.workpacksForReview
              .filter((wp) => wp.assignees.length > 0 || !wp.hasBeenCompleted)
              .map((wp) => (
                <ListItem key={wp.id} dense={true}>
                  <ListItemIcon>
                    <div className={classes.userProgress}>
                      <CircularProgress variant="determinate" value={100} color={"secondary"} />
                      <CircularProgress
                        value={wp.assignees.length > 0 ? wp.assignees[0].progressPercent : 0}
                        variant="static"
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    primary={wp.name}
                    secondary={wp.assignees.length > 0 ? wp.assignees[0].fullName : "Nincs hozzarendelt felhasznalo!"}
                  />
                </ListItem>
              ))}
          </List>
        </section>
      )}
    </Container>
  );
};

export default FileListComponent;
