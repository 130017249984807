import React from "react";
import { Container } from "@material-ui/core";

import {
  IImageInfoDisplaySettings,
  IInfoLabelSettings,
  IReviewFilterSettings,
} from "../../state/session/session.state";
import { InfoLabelSettingsCard } from "./infoLabelSettingsCard.component";
import { useStyles } from "./settings.styles";
import { ReviewFilterSettingsCard } from "./reviewFilterSettingsCard.component";
import { MiscSettingsCard } from "./miscSettingsCard.component";

export interface ISettingsProps {
  settings: IImageInfoDisplaySettings;
  reviewFilterSettings: IReviewFilterSettings;

  saveSettings: (settings: IImageInfoDisplaySettings) => void;
  saveReviewSettings: (settings: IReviewFilterSettings) => void;
}

export const SettingsComponent: React.FC<ISettingsProps> = ({
  settings,
  reviewFilterSettings,

  saveSettings,
  saveReviewSettings,
}: ISettingsProps) => {
  const theme = localStorage.getItem("preferredTheme");

  function save(label: keyof IImageInfoDisplaySettings, value: IInfoLabelSettings) {
    saveSettings({
      ...settings,
      [label]: value,
    });
  }
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md" className={classes.settingsView}>
      <InfoLabelSettingsCard
        title="Beteg azonosító"
        labelSettings={settings.patientIdLabel}
        save={(settings: IInfoLabelSettings) => save("patientIdLabel", settings)}
      />
      <InfoLabelSettingsCard
        title="Szelet cimke"
        labelSettings={settings.sliceLabel}
        save={(settings: IInfoLabelSettings) => save("sliceLabel", settings)}
      />
      <InfoLabelSettingsCard
        title="Lateralitás"
        labelSettings={settings.laterality}
        save={(settings: IInfoLabelSettings) => save("laterality", settings)}
      />
      <InfoLabelSettingsCard
        title="Szelet jelzo"
        isSliceIndicator={true}
        labelSettings={settings.sliceIndicator}
        save={(settings: IInfoLabelSettings) => save("sliceIndicator", settings)}
      />
      <ReviewFilterSettingsCard reviewSettings={reviewFilterSettings} save={saveReviewSettings} />
      <MiscSettingsCard
        displaySettings={settings}
        darkMode={theme === "dark"}
        save={(displaySettings, darkMode) => {
          saveSettings(displaySettings);
          const themeToSet = darkMode ? "dark" : "light";
          if (theme !== themeToSet) {
            localStorage.setItem("preferredTheme", themeToSet);

            window.dispatchEvent(new Event("storage"));
          }
        }}
      />
    </Container>
  );
};
