import * as base64 from "base64-js";

import { IFileListData } from "../../queries/fileList.query";
import {
  IImageInfoDisplaySettings,
  defaultImageInfoDisplaySettings,
  IReviewFilterSettings,
  defaultReviewFilterSettings,
} from "../../state/session/session.state";

const sessionIdStorageKey = "sessionId";
const sharedKeyStorageKey = "sharedKey";

const IDBName = "OrthoPred";
const fileStoreId = "files";

function promisifyRequest<T>(req: IDBRequest<T>): Promise<T> {
  return new Promise((res, rej) => {
    req.addEventListener("success", () => {
      res(req.result);
    });
    req.addEventListener("error", () => {
      rej(req.result);
    });
  });
}

export interface IStoredFileInfo {
  fileId: string;
  meta: any;
  blob: Blob;
}

// Casing is intentionally the same as IndexedDB
export class LocalDB {
  private db: Promise<IDBDatabase> | IDBDatabase;
  constructor(
    private readonly localStorage: Storage,
    private readonly sessionStorage: Storage,
    indexedDBFactory: IDBFactory
  ) {
    const idbRequest = indexedDBFactory.open(IDBName, 1);
    idbRequest.addEventListener("upgradeneeded", (event) => {
      idbRequest.result.createObjectStore(fileStoreId, { keyPath: "fileId" });
    });

    this.db = promisifyRequest<IDBDatabase>(idbRequest);
  }

  public setPreferredViewSettings(packId: string, labelingMode: string, seqTypes: string[]) {
    this.localStorage.setItem(
      "view_settings",
      JSON.stringify({
        packId,
        labelingMode,
        seqTypes,
      })
    );
  }

  public getPreferredViewSettings(
    packId: string
  ): { packId: string; labelingMode: string; seqTypes: string[] } | undefined {
    const stored = this.localStorage.getItem("view_settings");
    const res = stored && JSON.parse(stored);
    return res && res.packId === packId ? res : undefined;
  }

  public savePreferredLayout(
    numberOfTypes: number,
    layout: Array<{ x: number; y: number; w: number; h: number; i?: string }>
  ) {
    this.localStorage.setItem(`view_layout_${numberOfTypes}`, JSON.stringify(layout));
  }

  public getPreferredLayout(
    numberOfTypes: number
  ): Array<{ x: number; y: number; w: number; h: number; i: string }> | undefined {
    const stored = this.localStorage.getItem(`view_layout_${numberOfTypes}`);
    if (stored?.includes('static":true')) return undefined;
    return stored ? JSON.parse(stored) : undefined;
  }

  public getSessionInfo() {
    let sessionId = this.sessionStorage.getItem(sessionIdStorageKey);
    let sharedKeyB64 = this.sessionStorage.getItem(sharedKeyStorageKey);
    if (!sessionId || !sharedKeyB64) {
      sessionId = this.localStorage.getItem(sessionIdStorageKey);
      sharedKeyB64 = this.localStorage.getItem(sharedKeyStorageKey);
    }
    if (sessionId && sharedKeyB64) {
      return {
        sessionId,
        sharedKey: base64.toByteArray(sharedKeyB64),
      };
    }
    return undefined;
  }

  public saveSessionInfo(sessionId: string, sharedKey: Uint8Array, rememberMe = false) {
    const sharedKeyB64 = base64.fromByteArray(sharedKey);

    if (rememberMe) {
      this.localStorage.setItem(sessionIdStorageKey, sessionId);
      this.localStorage.setItem(sharedKeyStorageKey, sharedKeyB64);
    } else {
      this.sessionStorage.setItem(sessionIdStorageKey, sessionId);
      this.sessionStorage.setItem(sharedKeyStorageKey, sharedKeyB64);
    }
  }
  public clearSessionInfo() {
    this.localStorage.removeItem(sessionIdStorageKey);
    this.localStorage.removeItem(sharedKeyStorageKey);
    this.sessionStorage.removeItem(sessionIdStorageKey);
    this.sessionStorage.removeItem(sharedKeyStorageKey);
  }

  public getImageInfoDisplaySettings(email: string): IImageInfoDisplaySettings {
    const stored = this.localStorage.getItem(`${email}$$imageInfoDisplaySettings`);
    return stored ? { ...defaultImageInfoDisplaySettings, ...JSON.parse(stored) } : defaultImageInfoDisplaySettings;
  }

  public saveImageInfoDisplaySettings(email: string, settings: IImageInfoDisplaySettings) {
    this.localStorage.setItem(`${email}$$imageInfoDisplaySettings`, JSON.stringify(settings));
  }

  public getReviewFilterSettings(email: string): IReviewFilterSettings {
    const stored = this.localStorage.getItem(`${email}$$reviewFilterSettings`);
    return stored ? { ...defaultReviewFilterSettings, ...JSON.parse(stored) } : defaultReviewFilterSettings;
  }

  public saveReviewFilterSettings(email: string, settings: IReviewFilterSettings) {
    this.localStorage.setItem(`${email}$$reviewFilterSettings`, JSON.stringify(settings));
  }

  public async cleanFiles(fl: IFileListData) {
    const files = await this.getStoredFiles();
    for (const fileId of files) {
      if (
        !fl.me.currentProgresses.some((pack) =>
          pack.workpack.patients.some((patient) => patient.files.some((file) => file.id === fileId))
        )
      ) {
        await this.removeFile(fileId);
      }
    }
  }

  public async saveFile(fileId: string, fileMeta: any, fileBlob: Blob): Promise<IDBValidKey> {
    const db = await this.db;
    return promisifyRequest(
      db.transaction(fileStoreId, "readwrite").objectStore(fileStoreId).add({ fileId, meta: fileMeta, blob: fileBlob })
    );
  }

  public async removeFile(fileId: string): Promise<void> {
    const db = await this.db;
    return promisifyRequest(db.transaction(fileStoreId, "readwrite").objectStore(fileStoreId).delete(fileId));
  }

  public async getStoredFiles(): Promise<string[]> {
    const db = await this.db;
    return promisifyRequest(
      db.transaction(fileStoreId, "readwrite").objectStore(fileStoreId).getAllKeys() as IDBRequest<string[]>
    ); // We know that we only store string keys in our db.
  }

  public async getStoredFile(id: string): Promise<IStoredFileInfo> {
    const db = await this.db;
    return promisifyRequest(
      db.transaction(fileStoreId, "readwrite").objectStore(fileStoreId).get(id) as IDBRequest<IStoredFileInfo>
    ); // We know that we only store string keys in our db.
  }
}
