import React, { useState, useEffect } from "react";
import { List, ListItem, TextField, ListItemText, CircularProgress } from "@material-ui/core";

import FlipMove from "react-flip-move";

// import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

import { IUserInfo } from "../../state/admin/admin.reducers";
import { useStyles } from "./userSelect.style";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { UserPermissions } from "../../dtos/enums/userPermissions";
import { UserFilePermissions } from "../../dtos/enums/userFilePermissions";

export interface IUploadComponentProps {
  renderListItem: (user: IUserInfo) => React.ReactNode;
  renderStartingItem?: () => React.ReactNode;
  renderEndingItem?: () => React.ReactNode;
  rawItems?: boolean;
}

export const UserSelectComponent: React.FC<IUploadComponentProps> = ({
  renderListItem,
  renderStartingItem,
  renderEndingItem,
  rawItems = false,
}) => {
  const classes = useStyles();

  const [userFilter, setUserFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<IUserInfo[]>([]);

  const { data: queryRes, called, fetchMore, networkStatus, refetch } = useQuery<
    { users: { cursor: number; users: IUserInfo[] } },
    { cursor: number }
  >(
    gql`
      query userList($cursor: Int!) {
        users(cursor: $cursor) @connection(key: "userList") {
          users {
            id
            email
            fullName
            userPermissions
            filePermissions
          }
          cursor
        }
      }
    `,
    {
      variables: {
        cursor: 0,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const data = queryRes && queryRes.users;
  useEffect(() => {
    if (!data) {
      return;
    }
    const filterResults = data.users
      .filter(
        (a) =>
          a.email.toLocaleLowerCase().includes(userFilter.toLocaleLowerCase()) ||
          a.fullName.toLocaleLowerCase().includes(userFilter.toLocaleLowerCase())
      )
      .slice(0, 9);

    setFilteredUsers(
      filterResults.map((u) => ({
        ...u,
        userPermissions: UserPermissions.deserialize(u.userPermissions as any),
        filePermissions: UserFilePermissions.deserialize(u.filePermissions as any),
      }))
    );
  }, [data, setFilteredUsers, userFilter, fetchMore, networkStatus]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (filteredUsers.length < 9 && data.cursor !== 0 && networkStatus === 7) {
      fetchMore({
        variables: { cursor: data.cursor },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const oldUsers = prev.users.users.filter(
            (ou) => !fetchMoreResult.users.users.some((nu) => nu.email === ou.email)
          );
          return {
            ...prev,
            users: {
              ...prev.users,
              cursor: fetchMoreResult.users.cursor,
              users: [...oldUsers, ...fetchMoreResult.users.users],
            },
          };
        },
      });
    }
  }, [data, filteredUsers, fetchMore, networkStatus]);

  const inner = (
    <>
      <ListItem dense={true}>
        <ListItemText>
          <TextField
            className={classes.filterInput}
            label="Email vagy nev"
            onChange={(ev) => setUserFilter(ev.currentTarget.value)}
            value={userFilter}
            InputProps={{
              endAdornment:
                networkStatus === 4 ? (
                  <CircularProgress size={16} />
                ) : (
                  <RefreshIcon cursor="pointer" onClick={() => refetch()} />
                ),
            }}
          />
        </ListItemText>
      </ListItem>
      {renderStartingItem && renderStartingItem()}
      <FlipMove typeName={null}>
        {filteredUsers.map((user) => (
          <ListItem key={user.email}>{renderListItem(user)}</ListItem>
        ))}
        {!called ||
          (networkStatus < 7 && (
            <ListItem>
              <ListItemText className={classes.progressListItem}>
                <CircularProgress />
              </ListItemText>
            </ListItem>
          ))}
      </FlipMove>
      {renderEndingItem && renderEndingItem()}
    </>
  );

  if (rawItems) {
    return inner;
  }

  return (
    <section>
      <List>{inner}</List>
    </section>
  );
};
