import { getType } from "typesafe-actions";

import { OrthopredAction } from "../actions";
import * as SessionActions from "./session.actions";
import {
  ILoginState,
  LoginStatus,
  defaultImageInfoDisplaySettings,
  defaultReviewFilterSettings,
} from "./session.state";

const defaultState = {
  sessionInfo: undefined,
  userIdentity: undefined,
  imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
  reviewFilterSettings: defaultReviewFilterSettings,
};

export function loginState(
  state: ILoginState = {
    loginStatus: LoginStatus.Unknown,
    sessionInfo: undefined,
    userIdentity: undefined,
    imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
    reviewFilterSettings: defaultReviewFilterSettings,
  },
  action: OrthopredAction
): ILoginState {
  switch (action.type) {
    case getType(SessionActions.login.request):
      return {
        ...state,
        ...defaultState,
        loginStatus: LoginStatus.LoggingIn,
      };
    case getType(SessionActions.login.failure):
      return {
        ...defaultState,
        loginStatus: LoginStatus.LoggedOut,
      };
    case getType(SessionActions.login.success):
      return action.payload;

    case getType(SessionActions.logout.request):
      return {
        ...state,
        loginStatus: LoginStatus.LoggingOut,
      } as ILoginState;
    case getType(SessionActions.logout.failure):
      return state;
    case getType(SessionActions.logout.success):
      return {
        loginStatus: LoginStatus.LoggedOut,
        sessionInfo: undefined,
        userIdentity: undefined,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
        reviewFilterSettings: defaultReviewFilterSettings,
      };

    case getType(SessionActions.checkSession.request):
      return state;
    case getType(SessionActions.checkSession.failure):
      return {
        loginStatus: LoginStatus.LoggedOut,
        sessionInfo: undefined,
        userIdentity: undefined,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
        reviewFilterSettings: defaultReviewFilterSettings,
      };
    case getType(SessionActions.checkSession.success):
      return action.payload;

    case getType(SessionActions.saveImageInfoDisplaySettings.request):
      return state;
    case getType(SessionActions.saveImageInfoDisplaySettings.failure):
      return {
        ...state,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
      };
    case getType(SessionActions.saveImageInfoDisplaySettings.success):
      return {
        ...state,
        imageInfoDisplaySettings: action.payload,
      };

    case getType(SessionActions.saveReviewSettings.request):
      return state;
    case getType(SessionActions.saveReviewSettings.failure):
      return {
        ...state,
        reviewFilterSettings: defaultReviewFilterSettings,
      };
    case getType(SessionActions.saveReviewSettings.success):
      return {
        ...state,
        reviewFilterSettings: action.payload,
      };
    default:
      return state;
  }
}
