import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { WorkpackView } from "./workpackView.component";
import { match } from "react-router";

export const WorkpackViewContainer = connect((state: OrthopredState, ownProps: { match: match<{ id: string }> }) => {
  return {
    sequenceTypes: state.admin.sequenceTypeList,
    sequenceTypesState: state.admin.sequenceTypeListState,
    fileList: state.admin.fileList,
    userList: state.admin.userList,
    workpackList: state.admin.workpackList,
    packId: ownProps.match.params.id,
  };
})(WorkpackView);
