import React, { useState } from "react";
import { Typography, Switch, TextField, Paper, Button, FormControlLabel } from "@material-ui/core";
import { IReviewFilterSettings } from "../../state/session/session.state";
import { useStyles } from "./infoLabelSettingsCard.styles";

export const ReviewFilterSettingsCard: React.FC<{
  reviewSettings: IReviewFilterSettings;
  save: (value: IReviewFilterSettings) => void;
}> = ({ reviewSettings, save }) => {
  const classes = useStyles();

  const [minLabel, setMinLabel] = useState(reviewSettings.minLabel);
  const [note, setNote] = useState(reviewSettings.note);

  return (
    <Paper className={classes.settingsPaper}>
      <Typography variant="h4" className={classes.labelCardTitle}>
        <span> Ellenorzesi mod </span>
        <Switch
          checked={reviewSettings.enabled}
          onClick={() => save({ ...reviewSettings, enabled: !reviewSettings.enabled })}
          color="primary"
        />
      </Typography>
      <Typography variant="body1">Megjelenik a vizsgalat, ha: </Typography>

      <form className={classes.settingsForm}>
        <TextField
          label="Minimum cimke"
          type="number"
          value={minLabel}
          onChange={(e) => setMinLabel(Number.parseInt(e.currentTarget.value, 10))}
        />

        <FormControlLabel
          control={<Switch checked={note} onClick={() => setNote(!note)} color="primary" />}
          label="Van megjegyzes"
        />
      </form>
      <Button
        onClick={(e) => {
          save({
            ...reviewSettings,
            minLabel,
            note,
          });
        }}
        fullWidth={true}
        color="primary"
        variant="contained"
        className={classes.saveButton}
      >
        Ment
      </Button>
    </Paper>
  );
};
