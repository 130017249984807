import { LoginChallengeResponse, PasswordStretchParams, LoginResponse } from "../../dtos/user.dto";
import * as base64 from "base64-js";
import { WebApiError } from "./WebApiError";
import { PermissionAction } from "../../dtos/enums/permissionAction";
import { UserPermission } from "../../dtos/enums/userPermissions";
import { UserFilePermission } from "../../dtos/enums/userFilePermissions";
import { FilePermission } from "../../dtos/enums/filePermissions";
import { ILabelData, ISliceLabelData } from "../../state/labels/label.interfaces";
import { IWorkpack } from "../../state/admin/admin.reducers";

interface IQueryParamObject {
  [ind: string]: string;
}

function hexString(buffer: Uint8Array) {
  const byteArray = new Uint8Array(buffer);

  const hexCodes = Array.from(byteArray).map((value) => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, "0");
    return paddedHexCode;
  });

  return hexCodes.join("");
}

async function digestMessage(headerString: string, keyData: Uint8Array, body?: Uint8Array): Promise<Uint8Array> {
  const encoder = new TextEncoder();
  const headers = encoder.encode(headerString);

  let data: Uint8Array;
  if (body) {
    data = new Uint8Array(headers.length + body.length);
    data.set(headers, 0);
    data.set(body, headers.length);
  } else {
    data = headers;
  }

  const key = await window.crypto.subtle.importKey(
    "raw",
    keyData,
    {
      name: "HMAC",
      hash: { name: "SHA-256" },
    },
    false,
    ["sign"]
  );
  return new Uint8Array(await window.crypto.subtle.sign("HMAC", key, data));
}

function encodeBody(obj: object): Uint8Array {
  const encoder = new TextEncoder();
  const json = JSON.stringify(obj);
  return encoder.encode(json);
}

export class WebApi {
  constructor(
    private rootUrl: string = "",
    private fetch: WindowOrWorkerGlobalScope["fetch"],
    private xmlHttpRequest: typeof XMLHttpRequest
  ) {}

  async checkSession(sessionId: string, sharedKey: Uint8Array) {
    const resp = await this.request({
      method: "GET",
      path: "/api/session",
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async getLoginChallenge(email: string, A: string): Promise<LoginChallengeResponse> {
    const resp = await this.request({
      method: "GET",
      path: "/api/user/getLoginChallenge",
      queryParams: { email, A },
    });

    return resp.json();
  }

  public async getDefaultStretchParams(): Promise<PasswordStretchParams> {
    const resp = await this.request({
      method: "GET",
      path: "/api/user/getDefaultStretchParams",
    });
    return resp.json();
  }

  public async register(
    email: string,
    salt: Uint8Array,
    verifier: Uint8Array,
    stretchSalt: Uint8Array,
    passwordStretchParams: PasswordStretchParams,
    fullName: string
  ): Promise<void> {
    await this.request({
      method: "POST",
      path: "/api/user/register",
      body: {
        email,
        saltB64: base64.fromByteArray(salt),
        verifierB64: base64.fromByteArray(verifier),
        stretchSaltB64: base64.fromByteArray(stretchSalt),
        passwordStretchParams,
        fullName,
      },
    });
  }

  public async authenticate(email: string, A: string, clientProof: string): Promise<LoginResponse> {
    const resp = await this.request({
      method: "POST",
      path: "/api/user/authenticate",
      body: {
        email,
        A,
        clientProof,
      },
    });

    return resp.json();
  }

  public async logout(sessionId: string, sharedKey: Uint8Array): Promise<void> {
    await this.request({
      method: "DELETE",
      path: "/api/session",
      sessionId,
      sharedKey,
    });
  }

  public async saveFile(
    source: string,
    patientId: string,
    bodyPart: string,
    seqType: string,
    modality: string,
    imgNo: number,
    file: File,
    sessionId: string,
    sharedKey: Uint8Array,
    progressCallback: (prog: ProgressEvent) => void
  ) {
    const reader = new FileReader();
    const readPromise = new Promise((res) => (reader.onload = res));
    reader.readAsArrayBuffer(file);
    await readPromise;

    const { urlPart, headers } = await this.getHeadersAndFullUrl(
      "/api/file/save",
      undefined,
      undefined,
      new Uint8Array(reader.result as ArrayBuffer),
      sessionId,
      sharedKey,
      ""
    );
    const fd = new FormData();
    fd.append("file", file);

    fd.append("source", source);
    fd.append("patientId", patientId);
    fd.append("bodyPart", bodyPart);
    fd.append("seqType", seqType);
    fd.append("modality", modality);
    fd.append("images_no", imgNo as any);

    const req = new this.xmlHttpRequest();
    req.open("POST", this.rootUrl + urlPart);
    headers.forEach((v, k) => req.setRequestHeader(k, v));
    req.send(fd);
    const cancel = () => req.abort();

    let resolve: (r: any) => void;
    let reject: (r: any) => void;
    const prom = new Promise<{ fileId: string }>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    req.addEventListener("load", () => {
      if (req.status >= 200 && req.status < 300) {
        resolve(req.response);
      }
    });
    req.addEventListener("error", () => {
      reject(new WebApiError(req.status, req.responseText));
    });
    req.addEventListener("progress", progressCallback);
    return { prom, cancel };
  }

  public async getAccessibleFiles(sessionId: string, sharedKey: Uint8Array, cursor: number) {
    const resp = await this.request({
      method: "GET",
      path: "/api/file/listFiles",
      queryParams: { cursor: cursor.toString() },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }
  public async listFiles(
    sessionId: string,
    sharedKey: Uint8Array,
    cursor: number,
    sequenceType: string,
    emails: string[]
  ) {
    const resp = await this.request({
      method: "POST",
      path: "/api/admin/listFiles",
      body: { cursor, emails, sequenceType },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async getFile(
    fileId: string,
    progressCallback: (prog: ProgressEvent) => void,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    return await this.requestWithProgress({
      method: "GET",
      path: "/api/file/getFile",
      queryParams: { fileId },
      sessionId,
      sharedKey,
      progressCallback,
      responseType: "blob",
    });
  }

  public async getLabelPack(
    workpackId: string,
    progressCallback: (prog: ProgressEvent) => void,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    return await this.requestWithProgress({
      method: "GET",
      path: "/api/label/getLabelsForWorkpack",
      queryParams: { workpackId },
      sessionId,
      sharedKey,
      progressCallback,
      responseType: "blob",
    });
  }

  public async getLogsForPack(
    workpackId: string,
    progressCallback: (prog: ProgressEvent) => void,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    return await this.requestWithProgress({
      method: "GET",
      path: "/api/label/getLogsForWorkpack",
      queryParams: { workpackId },
      sessionId,
      sharedKey,
      progressCallback,
      responseType: "blob",
    });
  }

  public async listUsers(sessionId: string, sharedKey: Uint8Array, cursor: number) {
    const resp = await this.request({
      method: "GET",
      path: "/api/admin/listUsers",
      queryParams: { cursor: cursor.toString() },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async setUserPermission(
    email: string,
    action: PermissionAction,
    permission: UserPermission,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    const resp = await this.request({
      method: "POST",
      path: "/api/admin/setUserPermission",
      body: { email, action, permission },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async setFilePermission(
    email: string,
    fileId: string,
    action: PermissionAction,
    permission: FilePermission,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    const resp = await this.request({
      method: "POST",
      path: "/api/admin/setFilePermission",
      body: { email, fileId, action, permission },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async getSequenceTypes(sessionId: string, sharedKey: Uint8Array) {
    const resp = await this.request({
      method: "GET",
      path: "/api/admin/getSequenceTypes",
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async setUserFilePermission(
    email: string,
    action: PermissionAction,
    permission: UserFilePermission,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    const resp = await this.request({
      method: "POST",
      path: "/api/admin/setUserFilePermission",
      body: { email, action, permission },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async setUserLabelSettings(
    email: string,
    sequenceTypes: string[],
    labelModes: any,
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    const resp = await this.request({
      method: "POST",
      path: "/api/label/saveSettingsForUser",
      body: { email, sequenceTypes, labelModes },
      sessionId,
      sharedKey,
    });

    return resp.ok;
  }

  public async getUserLabelSettings(email: string, sessionId: string, sharedKey: Uint8Array) {
    const resp = await this.request({
      method: "GET",
      path: "/api/label/getSettingsForUser",
      queryParams: { email },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async getLabels(fileId: string, sessionId: string, sharedKey: Uint8Array) {
    const resp = await this.request({
      method: "GET",
      path: "/api/label/getLabels",
      queryParams: { fileId },
      sessionId,
      sharedKey,
    });

    const parsedResp = await resp.json();
    return { sliceLabels: parsedResp.sliceLabels.map(JSON.parse), labels: parsedResp.labels.map(JSON.parse) };
  }

  public async saveLabels(
    labels: ILabelData[],
    sliceLabels: ISliceLabelData[],
    sessionId: string,
    sharedKey: Uint8Array
  ) {
    await this.request({
      method: "POST",
      path: "/api/label/saveLabels",
      body: { labels, sliceLabels },
      sessionId,
      sharedKey,
    });
  }

  public async getWorkpacks(
    cursor: number,
    sessionId: string,
    sharedKey: Uint8Array
  ): Promise<{
    cursor: number;
    workpacks: Array<{ pack: IWorkpack; assignees: string[] }>;
  }> {
    const resp = await this.request({
      method: "GET",
      path: "/api/admin/getWorkpacks",
      queryParams: { cursor: cursor.toString() },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async createWorkpack(
    name: string,
    files: string[],
    sequenceTypes: string[],
    sessionId: string,
    sharedKey: Uint8Array
  ): Promise<{ id: string; created: string }> {
    const resp = await this.request({
      method: "POST",
      path: "/api/admin/createWorkpack",
      body: { name, files, sequenceTypes },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  public async assignWorkpack(
    email: string,
    packId: string,
    sessionId: string,
    sharedKey: Uint8Array
  ): Promise<{ files: string[] }> {
    const resp = await this.request({
      method: "POST",
      path: "/api/admin/assignWorkpack",
      body: { email, packId },
      sessionId,
      sharedKey,
    });

    return resp.json();
  }

  private async requestWithProgress({
    method,
    path,
    queryParams,
    body,
    hashParam,
    sessionId,
    sharedKey,
    progressCallback,
    responseType = "json",
  }: {
    method: "GET" | "POST" | "DELETE";
    path: string;
    queryParams?: IQueryParamObject;
    body?: object;
    hashParam?: string;
    sessionId?: string;
    sharedKey?: Uint8Array;
    responseType: XMLHttpRequestResponseType;
    progressCallback: (prog: ProgressEvent) => void;
  }): Promise<{ prom: Promise<any>; cancel: () => void }> {
    const bodyAsBytes = body ? encodeBody(body) : undefined;

    const { urlPart, headers } = await this.getHeadersAndFullUrl(
      path,
      queryParams,
      hashParam,
      bodyAsBytes,
      sessionId,
      sharedKey
    );

    const req = new this.xmlHttpRequest();
    req.open(method, this.rootUrl + urlPart);
    req.responseType = responseType;
    headers.forEach((v, k) => req.setRequestHeader(k, v));
    req.send(bodyAsBytes);
    const cancel = () => req.abort();

    let resolve: (r: any) => void;
    let reject: (r: any) => void;
    const prom = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    req.addEventListener("load", () => {
      if (req.status >= 200 && req.status < 300) {
        resolve(req.response);
      } else {
        reject(new WebApiError(req.status, responseType !== "blob" ? req.responseText : ""));
      }
    });
    req.addEventListener("error", () => {
      reject(new WebApiError(req.status, responseType !== "blob" ? req.responseText : ""));
    });
    req.addEventListener("progress", progressCallback);

    return { prom, cancel };
  }

  private async request({
    method,
    path,
    queryParams,
    body,
    hashParam,
    sessionId,
    sharedKey,
  }: {
    method: "GET" | "POST" | "DELETE";
    path: string;
    queryParams?: IQueryParamObject;
    body?: object;
    hashParam?: string;
    sessionId?: string;
    sharedKey?: Uint8Array;
  }): Promise<Response> {
    const bodyAsBytes = body ? encodeBody(body) : undefined;
    const { urlPart, headers } = await this.getHeadersAndFullUrl(
      path,
      queryParams,
      hashParam,
      bodyAsBytes,
      sessionId,
      sharedKey
    );

    const resp = await this.fetch(this.rootUrl + urlPart, {
      method,
      headers,
      body: bodyAsBytes,
    });

    if (resp.ok) {
      return resp;
    }

    throw new WebApiError(resp.status, await resp.text());
  }

  private async getHeadersAndFullUrl(
    path: string,
    queryParams?: IQueryParamObject,
    hashParam?: string,
    bodyAsBytes?: Uint8Array,
    sessionId?: string,
    sharedKey?: Uint8Array,
    contentType: string = "application/json"
  ) {
    const query =
      queryParams &&
      Object.keys(queryParams)
        .filter((k) => queryParams[k] !== undefined)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
        .join("&");
    const urlPart = `${path}${query ? "?" + query : ""}${hashParam ? "#" + hashParam : ""}`;
    const headers = new Headers();
    const signDate = new Date().toISOString();
    headers.set("Signaturedate", signDate);
    if (bodyAsBytes && contentType) {
      headers.set("content-type", contentType);
    }
    if (sessionId && sharedKey) {
      headers.set("SessionId", sessionId);
      const textToSign = `${urlPart},${signDate},${sessionId}`.toUpperCase();
      const signature = await digestMessage(textToSign, sharedKey, bodyAsBytes);
      headers.set("Authorization", `Session ${hexString(signature)}`);
    }
    return { urlPart, headers, bodyAsBytes };
  }
}
