import React, { useState } from "react";
import {
  Container,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  List,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";

import OpenIcon from "@material-ui/icons/OpenInBrowser";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import RefreshIcon from "@material-ui/icons/Refresh";

import gql from "graphql-tag";
import { History } from "history";

import { ProgressFab } from "../progressFab.component";
import { useStyles } from "./workpackList.style";
import { useListQuery } from "../../queries/useListQuery";
import { LoadingView } from "../loadingView.component";
import { useMutation } from "@apollo/react-hooks";

export interface IWorkpackListComponentProps {
  history: History;
}

export const WorkpackListComponent: React.FC<IWorkpackListComponentProps> = ({
  history,
}: IWorkpackListComponentProps) => {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const { list, fullyLoaded, refetch, fetchMore, networkStatus } = useListQuery<{
    id: string;
    name: string;
    creator: string;
    created: Date;
    patients: Array<{ id: string }>;
    assignees: {
      email: string;
      fullName: string;
      progressPercent: number;
    }[];
  }>(
    "workpacks",
    gql`
      query workpackList($cursor: Int) {
        workpacks(cursor: $cursor) @connection(key: "workpackList") {
          list {
            name
            id
            creator
            created
            patients {
              id
            }
            assignees {
              email
              fullName
              progressPercent
            }
          }
          cursor
        }
      }
    `
  );

  const [deletePack] = useMutation<{ deletePack: { id: string } }>(
    gql`
      mutation deletePack($id: String!) {
        deletePack(packId: $id)
      }
    `,
    {
      refetchQueries: ["workpackList"],
      awaitRefetchQueries: true,
    }
  );

  if (!list) {
    return <LoadingView />;
  }

  return (
    <Container component="main" maxWidth="md">
      <span className={classes.refresh}>
        <ProgressFab onClick={refetch} isWorking={networkStatus < 7} />
      </span>
      <section className={classes.listContainer}>
        <List>
          <ListItem>
            <ListItemText>
              <Button onClick={() => history.push(`/workpacks/new`)} variant="contained">
                Új hozzáadása
              </Button>
            </ListItemText>
          </ListItem>
          <ListItem dense={true}>
            <ListItemText>
              <TextField
                label="Kereses"
                onChange={(ev) => setFilter(ev.currentTarget.value)}
                value={filter}
                InputProps={{
                  endAdornment:
                    networkStatus === 4 ? (
                      <CircularProgress size={16} />
                    ) : (
                      <RefreshIcon cursor="pointer" onClick={() => refetch()} />
                    ),
                }}
              />
            </ListItemText>
          </ListItem>
          {list
            .filter((wp) => {
              const lowerFilter = filter.toLocaleLowerCase();
              return (
                wp.name.toLocaleLowerCase().includes(lowerFilter) ||
                wp.assignees.some(
                  (a) =>
                    a.email.toLocaleLowerCase().includes(lowerFilter) ||
                    a.fullName.toLocaleLowerCase().includes(lowerFilter)
                )
              );
            })
            .map((wp) => {
              return (
                <ListItem key={wp.id}>
                  <ListItemIcon>
                    <div className={classes.userProgress}>
                      <CircularProgress variant="determinate" value={100} color={"secondary"} />
                      {wp.assignees.length === 1 && (
                        <CircularProgress value={wp.assignees[0].progressPercent} variant="static" />
                      )}
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${wp.name} (${wp.creator} - ${wp.created.toLocaleString()})`}
                    secondary={wp.assignees.map((a) => `${a.fullName} - ${a.progressPercent}%`).join(" | ")}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => history.push(`/workpacks/${wp.id}`)}>
                      <OpenIcon />
                    </IconButton>
                    <IconButton onClick={() => history.push(`/workpacks/new#${wp.id}`)}>
                      <CopyIcon />
                    </IconButton>
                    <IconButton onClick={() => deletePack({ variables: { id: wp.id } })}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          {networkStatus === 7 && !fullyLoaded && (
            <ListItem>
              <ListItemText onClick={fetchMore}>Tovabbiak</ListItemText>
              <ListItemSecondaryAction>{networkStatus === 5 && <CircularProgress />}</ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </section>
    </Container>
  );
};

export default WorkpackListComponent;
