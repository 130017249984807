import React, { useState } from "react";
import {
  Container,
  List,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";

import { useStyles } from "./workflowList.style";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import AddIcon from "@material-ui/icons/Add";
import SeeIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/DeleteForever";

import { LabelMode, labelModeListQuery } from "../labelMode/labelModeList.query";
import { IStepInfo } from "../../queries/fileList.query";
import { MultiChipInput } from "../MultiChipInput";

export type Workflow = {
  id: string;
  name: string;
  steps: IStepInfo[];
};

export const workflowListQuery = gql`
  query listWorkflows {
    listWorkflows {
      id
      name
      steps {
        name
        labelingModes
        lowerSliceLimit
        upperSliceLimit
      }
    }
  }
`;

export const WorkflowListComponent: React.FC = () => {
  const classes = useStyles();

  const { data: labelModeList } = useQuery<{ listLabelModes: LabelMode[] }>(labelModeListQuery);

  const { data: workflowList } = useQuery<{ listWorkflows: Workflow[] }>(workflowListQuery);

  const [createWorkflow, { loading: createLoading }] = useMutation(
    gql`
      mutation createWorkflow($name: String!, $steps: [WorkStepCreate!]!) {
        createWorkflow(name: $name, steps: $steps) {
          id
        }
      }
    `,
    {
      refetchQueries: [{ query: workflowListQuery }],
    }
  );

  const [workflowId, setWorkflowId] = useState<string | undefined>();
  const [workflowName, setWorkflowName] = useState<string | undefined>();
  const [steps, setSteps] = useState<IStepInfo[] | undefined>();

  function setStepProp<K extends keyof IStepInfo>(ind: number, prop: K, newLabel: IStepInfo[K]) {
    setSteps((oldSteps) => {
      const steps = Array.from(oldSteps || []);
      steps.splice(ind, 1, {
        ...steps[ind],
        [prop]: newLabel,
      });
      return steps;
    });
  }

  function clearForm() {
    setWorkflowId(undefined);
    setWorkflowName(undefined);
    setSteps(undefined);
  }

  function openNewWorkflow() {
    setWorkflowName("");
    setSteps([
      {
        name: "Lepes 1",
        labelingModes: [],
        lowerSliceLimit: undefined,
        upperSliceLimit: undefined,
      },
    ]);
  }

  function openWorkflow(wf: Workflow) {
    setWorkflowId(wf.id);
    setWorkflowName(wf.name);
    setSteps(wf.steps);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Dialog open={workflowName !== undefined} onClose={() => clearForm()}>
        <DialogTitle>Munkafolyamat {workflowId !== undefined ? "megtekintese" : "hozzaadasa"}</DialogTitle>
        <DialogContent>
          {steps && (
            <form
              className={classes.form}
              onSubmit={(ev) => {
                if (workflowId === undefined) {
                }
                ev.preventDefault();
              }}
            >
              <TextField
                disabled={workflowId !== undefined}
                label="Munkafoylamat neve"
                fullWidth={true}
                value={workflowName}
                onChange={(ev) => setWorkflowName(ev.target.value)}
              />
              <br />
              <Typography variant="h6">Lépések</Typography>
              {steps.map((p, i) => (
                <form key={`workpack_step_${i}`}>
                  <hr />
                  <TextField
                    label={"Lépés neve"}
                    disabled={workflowId !== undefined}
                    value={p.name}
                    onChange={(ev) => setStepProp(i, "name", ev.target.value)}
                  />
                  {workflowId === undefined && (
                    <IconButton
                      onClick={() => setSteps((ol) => ol?.filter((_, ind) => ind !== i))}
                      disabled={steps.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <MultiChipInput
                    disabled={workflowId !== undefined}
                    label="Elérhető cimkézési módok"
                    isFullWidth={true}
                    classes={{
                      wrapper: classes.chipInputWrapper,
                      chip: classes.chipInputChip,
                      popper: classes.chipPopper,
                    }}
                    defaultSelectedItems={p.labelingModes.map((a) => ({ key: a, label: a, value: a }))}
                    selectedChanged={(selection: string[]) => setStepProp(i, "labelingModes", selection)}
                    getSuggestions={(val: string) => {
                      return (labelModeList?.listLabelModes || [])
                        .filter((m) => m.name.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
                        .map((m) => ({ key: m.id, label: m.name, value: m.id }));
                    }}
                  />
                  {(workflowId === undefined || p.lowerSliceLimit !== null) && (
                    <TextField
                      disabled={workflowId !== undefined}
                      type="number"
                      label="Min slice"
                      value={p.lowerSliceLimit}
                      onChange={(e) => setStepProp(i, "lowerSliceLimit", Number.parseInt(e.currentTarget.value, 10))}
                    ></TextField>
                  )}

                  {(workflowId === undefined || p.upperSliceLimit !== null) && (
                    <TextField
                      disabled={workflowId !== undefined}
                      type="number"
                      label="Max slice"
                      value={p.upperSliceLimit}
                      onChange={(e) => setStepProp(i, "upperSliceLimit", Number.parseInt(e.currentTarget.value, 10))}
                    ></TextField>
                  )}
                </form>
              ))}
              {workflowId === undefined && (
                <IconButton
                  onClick={() =>
                    setSteps((os) =>
                      (os || []).concat([
                        {
                          name: `Lepes ${(os?.length || 0) + 1}`,
                          labelingModes: [],
                        },
                      ])
                    )
                  }
                >
                  <AddIcon />
                </IconButton>
              )}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {workflowId === undefined && (
            <Button
              disabled={createLoading}
              variant="contained"
              color="primary"
              onClick={() =>
                createWorkflow({
                  variables: {
                    name: workflowName,
                    steps: steps,
                  },
                }).then(clearForm)
              }
            >
              {createLoading ? <CircularProgress /> : "Mentés"}
            </Button>
          )}
          <Button onClick={() => clearForm()}>Bezar</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h2">
        Munkafolyamatok
        <IconButton onClick={() => openNewWorkflow()}>
          <AddIcon />
        </IconButton>
      </Typography>
      <List>
        {workflowList?.listWorkflows.map((w) => (
          <ListItem>
            <ListItemText primary={w.name} secondary={w.steps.map((s) => s.name).join(", ")} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => openWorkflow(w)}>
                <SeeIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
