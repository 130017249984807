import { useContext } from "react";

import { LocalDBContext } from "./localDb.context";
import { WebApiContext } from "./webApi.context";
import { useState } from "react";
import { useEffect } from "react";
import { ITaskState, TaskContext } from "./task.hooks";

export type IDownloadIntent =
  | {
      patientId: string;
      sequenceType: string;
      fileId: string;
      meta: any;
      type: "File";
    }
  | {
      packId: string;
      type: "LabelPack";
    }
  | {
      packId: string;
      type: "LogPack";
    };

export function useFileDownloader(): [
  ITaskState,
  (fileId: string, patientId: string, sequenceType: string, meta: any) => void,
  (packId: string) => void,
  (packId: string) => void
] {
  const localDb = useContext(LocalDBContext);
  const webApi = useContext(WebApiContext);
  const [downloadIntents, setDownloadIntents] = useState<IDownloadIntent[]>([]);
  const { tasks, addTask, updateTask, markFailure, markSuccess } = useContext(TaskContext)!;

  useEffect(() => {
    const [intent, ...rest] = downloadIntents;
    if (!intent) {
      return;
    }

    let cancelled = false;
    (async () => {
      addTask(intent, () => (cancelled = true));
      const sessionInfo = localDb.getSessionInfo();
      if (!sessionInfo) {
        markFailure(intent, "NotLoggedIn");
      }

      const { sharedKey, sessionId } = sessionInfo!;
      if (cancelled) {
        markFailure(intent, "Cancelled");
      }
      let call;
      switch (intent.type) {
        case "File":
          const f = await localDb.getStoredFile(intent.fileId);
          if (f) {
            markSuccess(intent);
            return;
          }
          call = await webApi.getFile(
            intent.fileId,
            (prog) => {
              updateTask(
                intent,
                prog.lengthComputable ? (prog.total / prog.loaded) * 100 : -1,
                prog.lengthComputable,
                cancel,
                "downloading"
              );
            },
            sessionId,
            sharedKey
          );
          break;
        case "LabelPack":
          call = await webApi.getLabelPack(
            intent.packId,
            (prog) => {
              updateTask(
                intent,
                prog.lengthComputable ? (prog.total / prog.loaded) * 100 : -1,
                prog.lengthComputable,
                cancel,
                "downloading"
              );
            },
            sessionId,
            sharedKey
          );
          break;
        case "LogPack":
          call = await webApi.getLogsForPack(
            intent.packId,
            (prog) => {
              updateTask(
                intent,
                prog.lengthComputable ? (prog.total / prog.loaded) * 100 : -1,
                prog.lengthComputable,
                cancel,
                "downloading"
              );
            },
            sessionId,
            sharedKey
          );
          break;
      }
      const { prom, cancel } = call;
      if (cancelled) {
        cancel();
        markFailure(intent, "Cancelled");
      }
      updateTask(intent, 0, true, cancel, "running");
      prom.then(
        async (blob) => {
          switch (intent.type) {
            case "File": {
              await localDb.saveFile(intent.fileId, intent.meta, blob);
              markSuccess(intent);
              break;
            }
            case "LabelPack": {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = `${intent.packId}_labels.zip`;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
              break;
            }
            case "LogPack": {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = `${intent.packId}_logs.zip`;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
              break;
            }
          }
        },
        (err) => markFailure(intent, (err && err.message) || err)
      );
    })();
    setDownloadIntents(rest);
  }, [downloadIntents, addTask, localDb, markFailure, markSuccess, updateTask, webApi]);

  return [
    tasks,
    (fileId: string, patientId: string, sequenceType: string, meta: any) =>
      setDownloadIntents((intents) => intents.concat([{ fileId, patientId, sequenceType, meta, type: "File" }])),
    (packId: string) => setDownloadIntents((intents) => intents.concat([{ packId, type: "LabelPack" }])),
    (packId: string) => setDownloadIntents((intents) => intents.concat([{ packId, type: "LogPack" }])),
  ];
}
