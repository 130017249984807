import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from "@material-ui/core";

import { useStyles } from "./finishPackModal.style";
import { useMutation } from "@apollo/react-hooks";
import { userFileListQuery } from "../../queries/fileList.query";
import { gql } from "apollo-boost";

export type FinishPackModalProps = {
  pack: {
    id: string;
    name: string;
  };
  open: boolean;
  onClose: () => void;
};

export const FinishPackModalComponent: React.FunctionComponent<FinishPackModalProps> = ({ pack, open, onClose }) => {
  const classes = useStyles();

  const [finishWorkpack, { loading: finishLoading }] = useMutation(
    gql`
      mutation finishWorkpack($packId: String!) {
        finishPack(packId: $packId) {
          __typename
          id
        }
      }
    `,
    {
      refetchQueries: [{ query: userFileListQuery }],
      awaitRefetchQueries: true,
    }
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Munkacsomag leadas ({pack.name})</DialogTitle>
      <DialogContent>
        A munkacsomag leadasaval elveszik a hozzaferesed, azt csak egy adminisztrator adhatja vissza.
      </DialogContent>
      <DialogActions>
        <Button
          disabled={finishLoading}
          variant="contained"
          color="primary"
          onClick={() =>
            finishWorkpack({
              variables: {
                packId: pack.id,
              },
            }).then(() => onClose())
          }
        >
          {finishLoading ? <CircularProgress /> : "Leadom"}
        </Button>
        <Button onClick={() => onClose()}>Megsem</Button>
      </DialogActions>
    </Dialog>
  );
};
